<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="d-flex align-center justify-space-between">
                <h1 class="text-h4" style="flex-grow: 1">Sorggruppe metoder</h1>
            </v-col>
            <v-col cols="12" md="6">
                <v-data-table :headers="headers" :items="metoder" sort-by="opprettet" :sort-desc="true" :items-per-page="-1" must-sort hide-default-footer>
                    <template v-slot:header.rediger>
                        <div class="d-flex justify-end">
                            <a v-on:click="toggleViewActive" v-text="inkluderInaktive ? 'Skjul slettete' : 'Vis slettete'" style="text-align: right"></a>
                        </div>
                    </template>
                    <template v-slot:item.navn="{ item }">
                        <template v-if="!item.id || item.rediger">
                            <v-text-field ref="navn" v-model="item.navn" label="Ny gruppemetode" single-line></v-text-field>
                        </template>
                        <span v-else v-html="item.navn" v-bind:style="{ textDecoration: item.aktiv ? 'none' : 'line-through' }"></span>
                    </template>
                    <template v-slot:item.rediger="{ item }">
                        <div v-if="!item.id || item.rediger" class="d-flex justify-end">
                            <v-btn v-if="!item.id" v-on:click="insert(item)" outlined>Legg til</v-btn>
                            <v-btn v-else-if="item.rediger" v-on:click="save(item)" outlined>Lagre</v-btn>
                        </div>
                        <div v-else-if="item.aktiv" class="d-flex justify-end">
                            <v-icon small class="mr-2" v-on:click="edit(item)">mdi-pencil</v-icon>
                            <v-icon small v-on:click="inactivate(item)">mdi-delete</v-icon>
                        </div>
                        <div v-else class="d-flex justify-end">
                            <v-icon small v-on:click="activate(item)">mdi-delete-restore</v-icon>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'GriefMethods',
    data() {
        return {
            headers: [
                { text: '', value: 'navn', sortable: false },
                { text: '', value: 'rediger', sortable: false, width: '80px' },
            ],
            metoder: [],
            inkluderInaktive: false,
        };
    },
    created: async function () {
        await this.update();
    },
    methods: {
        ...mapActions('api', ['request']),

        /**
         * update
         */
        update: async function () {
            try {
                const metoder = [];
                const response = await this.request({
                    method: 'get',
                    url: '/sorggruppe/metode',
                    params: { inkluderInaktive: this.inkluderInaktive },
                });

                if (response && Array.isArray(response)) {
                    this.metoder = [];
                    for (const type of response) {
                        type.rediger = false;
                        metoder.push(type);
                    }
                }
                metoder.push({ navn: '' });

                this.metoder = metoder;
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * toggleViewActive
         */
        toggleViewActive: async function () {
            this.inkluderInaktive = !this.inkluderInaktive;
            await this.update();
        },

        /**
         * insert
         */
        insert: async function (item) {
            if (item.navn) {
                const data = new FormData();
                data.append('navn', item.navn);

                const response = await this.request({
                    method: 'post',
                    url: '/sorggruppe/metode',
                    data: data,
                });
                if (response && typeof response.id != 'undefined') {
                    await this.update(response);
                }
            }
        },

        /**
         * edit
         */
        edit: async function (item) {
            item.rediger = true;
        },

        /**
         * save
         */
        save: async function (item) {
            if (item.id && item.navn) {
                item.navn = item.navn.trim();

                const data = new FormData();
                data.append('navn', item.navn);

                const response = await this.request({
                    method: 'post',
                    url: '/sorggruppe/metode/' + item.id + '/navn',
                    data,
                });

                if (response) {
                    item.rediger = false;
                }
            }
        },

        /**
         * inactivate
         */
        inactivate: async function (item) {
            if (item.id) {
                const response = await this.request({
                    method: 'post',
                    url: '/sorggruppe/metode/' + item.id + '/inaktiver',
                });
                if (response && typeof response.id != 'undefined') {
                    item.aktiv = false;
                }
            }
        },

        /**
         * activate
         */
        activate: async function (item) {
            if (item.id) {
                const response = await this.request({
                    method: 'post',
                    url: '/sorggruppe/metode/' + item.id + '/aktiver',
                });
                if (response && typeof response.id != 'undefined') {
                    item.aktiv = true;
                }
            }
        },
    },
};
</script>
